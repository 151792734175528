import React from "react";
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

const Success = () => {
  return (
    <div className="success_page">
      <div className="container-centered">
        <StaticImage
          alt="Anes Mulalic, Email Sent"
          src="../images/email_sent.png"
          className="success_page--image"
          placeholder="blurred"
        />

        <h2 className="success_page--heading">Thank you! !</h2>

        <p className="success_page--subheading">
          Your message has been successfully sent.
        </p>
        <br/>
        <Link to="/" className="text-white">Back to homepage</Link>
      </div>
    </div>
  );
};

export default Success;
